<template>
    <div class="recharge-info">
        <van-nav-bar :title="$t('recharge.recharge')" class="nav-bar">
            <template #left>
                <van-icon name="arrow-left" color="#fff" @click="back()" />
            </template>
            <template #right>
                <span class="nav-right" @click="$router.push({ path: '/RechargeRecords' })">{{
                    $t("recharge.rechargeRecords")
                    }}</span>
            </template>
        </van-nav-bar>
        <div style="display: flex;flex-direction: column;justify-content: center;align-items: center;">
            <van-cell v-if="method == 'bank'">
                <!-- 使用 right-icon 插槽来自定义右侧图标 -->
                <template #title>
                    <div class="left">
                        <div class="label">{{ $t('my.account_no') }}:</div>
                        <div id="bank_num">{{ info.bank_num }}</div>
                    </div>
                </template>
                <template>
                    <van-icon name="description" data-clipboard-target="#bank_num" class="bankNumBtn" />
                </template>
            </van-cell>
            <van-cell v-if="method == 'bank'">
                <template #title>
                    <div class="left">
                        <div class="label">{{ $t('my.name') }}:</div>
                        <div id="bank_name">{{ info.bank_name }}</div>
                    </div>
                </template>
                <template>
                    <van-icon name="description" data-clipboard-target="#bank_name" class="bankNumBtn" />
                </template>
            </van-cell>
            <van-cell v-if="method == 'bank'">
                <template #title>
                    <div class="left">
                        <div class="label">{{ $t('my.bank_addr') }}:</div>
                        <div id="bank_addres">{{ info.bank_addres }}</div>
                    </div>
                </template>
                <template>
                    <van-icon name="description" data-clipboard-target="#bank_addres" class="bankNumBtn" />
                </template>
            </van-cell>
            <div v-if="method == 'usdt'" style="display: flex; flex-direction: row; padding: 10px;">
                <div style="color: #333;">{{ $t('my.wallet_address') }}:</div>
                <div id="bank_num" style="word-wrap: break-word; word-break: break-all;">{{ info.wallet_address }}</div>                
                <van-icon name="description" data-clipboard-target="#bank_num" class="bankNumBtn" />
            </div>
            <img v-if="method == 'usdt'" id="logo-img" class="logo-img" @click="scale()"  :src="this.$store.getters.getBaseInfo.qrcode !== undefined
                ? this.$store.getters.getBaseInfo.qrcode
                : '/img/null.png'
                " />
        </div>

        <div class="bottom">
            <div class="title">{{ $t('my.money') }}</div>
            <div class="money">{{ method == 'bank' ? $t("symbol") : $t("money_symbol") }}<input type="text"
                    v-model="form.money" placeholder="0.00"></div>
            <div class="select-outer">
                <div class="select-item" v-for="(item, index) in list" :key="index" @click="moneySelect(index)"
                    :style="{ background: current_money == index ? '#5189FD' : '', color: current_money == index ? '#fff' : '' }">
                    {{ method == 'bank' ? $t("symbol") : $t("money_symbol")  }} {{
                        item.money }}</div>
            </div>
            <van-uploader :after-read="choosePic" style="width: 100%;">
                <div class="upload-title">
                    <div class="upload-inner">{{ $t('my.shortcut') }}</div><van-icon name="arrow" color="#fff" />
                </div>
            </van-uploader>
            <div class="pic-outer">
                <img :src="src" alt="" v-if="src != ''" class="pic">
            </div>
            <div class="button" @click="submit">{{ $t('my.submit') }}</div>
        </div>
    </div>
</template>
<script>
import { Toast } from 'vant';
import axios from 'axios'
export default {
    data() {
        return {
            form: {
                bank: '',
                name: '',
                bankname: '',
                img: '',
                money: ''
            },
            method: this.$route.query.method,
            src: '',
            file: {},
            info: {},
            current_grid: 0,
            list: [
                {
                    money: '1000',
                    is_select: false
                },
                {
                    money: '2000',
                    is_select: false
                },
                {
                    money: '5000',
                    is_select: false
                },
                {
                    money: '10000',
                    is_select: false
                },
                {
                    money: '50000',
                    is_select: false
                },
                {
                    money: '100000',
                    is_select: false
                }
            ],
            current_money: -1,
            is_recharge: false
        }
    },
    created() {
        // this.infosGet()
        let clipboard = new this.clipboard(".bankNumBtn");
        clipboard.on('success', function () {
            Toast.success("")
        });
        clipboard.on('error', function () {
            Toast.fail("")
        });
        this.getBaseInfo()
        this.getUserRechargeList()
    },
    methods: {
        getUserRechargeList() {
            this.$http({
                method: 'get',
                url: 'rechargeRecordsAPI'
            }).then(res => {
                if (res.code === 200) {
                    // this.list = res.data;
                    console.log(res.data);
                    res.data.forEach((item) => {
                        if (item.status == 0) {
                            this.is_recharge = true
                        }
                    })
                } else if (res.code === 401) {
                    this.$toast(res.msg);
                }
            })
        },
        getBaseInfo() {
            this.$http({
                method: 'get',
                url: 'base_info'
            }).then(res => {
                console.log(res);
                if (!localStorage.getItem('token')) {
                    this.$router.push({ path: '/Login' })
                }
                this.$store.commit('setBaseInfoValue', res.data);
                this.info = this.$store.state.baseInfo
            })
        },
        submit() {
            if (this.form.src == '') {
                Toast(this.$t('recharge.is_img_empty'))
                return
            }
            if (this.form.money == '') {
                Toast(this.$t('recharge.is_money_empty'))
                return
            }
            if (this.is_recharge == false) {
                var formData = new FormData()
                formData.append('file', this.file)
                axios.post('https://api.lucky9888.com/api/system/doupload', formData).then(res => {
                    this.form.img = res.data.data
                    var obj = {
                        img: this.form.img,
                        money: this.form.money,
                        method: this.method,
                    }
                    this.$http({
                        url: 'rechargeAPI',
                        method: 'post',
                        data: obj,
                    }).then(res => {
                        if(res.code == 401){
                            Toast.fail(res.msg)
                            return;
                        }
                        if (res.data) {
                            Toast.success('')
                            this.$router.go(-2)
                        }
                    })
                })
            } else {
                Toast(this.$t('recharge.has_recharge'))
            }

        },
        choosePic(e) {
            this.src = e.content
            this.file = e.file
        },
        afterRead(file) {
            // 此时可以自行将文件上传至服务器
            console.log(file);
        },
        onSubmit(values) {
            console.log('submit', values);
        },
        back() {
            return window.history.back();
        },
        infosGet() {
            this.info = this.$store.state.baseInfo
        },
        moneySelect(i) {
            if (this.current_money == i) {
                this.current_money = -1
                this.form.money = ''
            } else {
                this.current_money = i
                this.form.money = this.list[i].money
            }
        },
        uploadHandle() {
            this.$http({
                method: 'post',
                data: { header_img: this.isActive },
                url: 'user_header_img'
            }).then(res => {
                if (res.code === 200) {
                    this.getUserInfo();
                    this.$toast(res.msg);
                    this.show = false;
                } else if (res.code === 401) {
                    this.$toast(res.msg);
                }
            })
        },
        scale(){
            const logoimg = document.getElementById('logo-img');
            const bigImg = window.open('', '_blank');
            bigImg.document.write(`<img src="${logoimg.src}" width="800">`);
        }
    }
}
</script>
<style lang='less' scoped>
.button {
    margin: 30px auto;
    padding: 20px;
    text-align: center;
    color: #fff;
    background: #00eeff;
    border-radius: 100px;
}

/deep/ .van-uploader__wrapper,
.van-uploader__wrapper {
    width: 100% !important;
    display: inline-block;
}

.pic-outer {
    text-align: center;
    margin-top: 50px;
}

.pic {
    width: 200px;
    height: 200px;
    border-radius: 20px;
}

.logo-img {
    width: 350px;
    height: 350px;
    margin-top: 10px;
    -o-object-fit: contain;
    object-fit: contain;
}

.upload-title {
    background: #5189FD;
    padding: 20px 40px;
    border-radius: 30px;
    color: #fff;
    margin-top: 50px;
    font-size: 26px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.money {
    margin-top: 30px;
    font-size: 40px;
    font-weight: bold;
    border-bottom: 1px solid #dbdbdb;
}

input {
    border: 0;
    // border-bottom: 1px solid #f0f0f0;
    padding: 20px;
}

.title {
    font-size: 33px;
    font-weight: 500;
    font-family: Arial;
}

.bottom {
    position: absolute;
    width: 100%;
    height: 60%;
    padding: 30px;
    background: #fff;
    bottom: 0;
    left: 0;
    border-radius: 50px 50px 0 0;
    font-size: 32px;
}

.select-outer {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 50px;
}

.recharge-info {
    background: #f0f0f0;
    height: 100%;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
}

.select-item {
    padding: 5px 20px;
    border: 1px solid #acacac;
    border-radius: 100px;
    font-size: 26px;
    color: #666666;
    margin-bottom: 25px;
}

.item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
}

.left {
    display: flex;
    align-items: center;
    font-size: 30px;
    color: #a5a5a5;
}

.value {
    display: flex;
    align-items: flex-end;
}

.label {
    width: 60%;
    color: #333;
}

/deep/ .van-cell {
    padding: 10px 20px;
    background-color: transparent;
}

/deep/ .van-icon {
    font-size: 36px;
    color: rgb(0, 162, 255);
}
</style>