<template>
  <div class="mine page">
    <div class="page-bg"></div>
    <div class="wrapper">
      <van-pull-refresh v-model="isLoading" @refresh="onRefresh" :pulling-text="$t('my.pull')"
        :loosing-text="$t('my.release')" :loading-text="$t('my.loading')">
        <div class="header">
          <van-nav-bar class="nav-bar">
            <template #right>
              <van-icon name="setting-o" @click="showSetting()" color="#fff" />
            </template>
          </van-nav-bar>
          <div class="user-wrapper" @click="doLogin()">
            <!-- <van-image round class="user_img" :src="this.userInfo.header_img">
               <template v-slot:loading>
                 <van-loading type="spinner"/>
               </template>
             </van-image> -->
            <div class="login-content">
              <p class="login-btn">{{ this.userInfo.username }}</p>
              <!-- <p class="login-label">{{ this.userInfo.ip }}</p> -->
            </div>
          </div>
          <div class="header-bottom">
            <div class="header-bottom-item" @click="doPay()">
              <img src="img/mine/recharge.png" alt="" class="header-bottom-icon">
              <div>{{ $t("my.recharge") }}</div>
            </div>
            <div class="header-bottom-item" @click="doWithdrawal()">
              <img src="img/mine/withdraw.png" alt="" class="header-bottom-icon">
              <div>{{ $t("my.withdraw") }}</div>
            </div>
          </div>
        </div>
        <div class="content">
          <!-- <div class="finance">
            <div class="finance-item" @click="doPay()">
              <van-icon class="icon" style="" name="peer-pay" />
              <span class="text">{{ $t("my.recharge") }}</span>
            </div>
            <div class="line"></div>
            <div class="finance-item" @click="doWithdrawal()">
              <van-icon class="icon" name="idcard" />
              <span class="text">{{ $t("my.withdraw") }}</span>
            </div>
          </div> -->
          <div v-if="this.userInfo.money" class="wallet">
            <div class="part-1 van-hairline--bottom">
              <p class="flex-1 font-28 font-primary-color">{{ $t("my.my_balance") }}</p>
              <!-- <span class="font-28 font-gray">{{$t("my.detail")}}</span>
                  <van-icon class="font-gray" style="font-size: 28px" name="arrow" /> -->
            </div>
            <div class="part-2">
              <p class="balance van-ellipsis">{{ this.userInfo.money }}</p>
              <span class="font-28 font-gray">{{ $t("my.balance") }}({{ $t("money_symbol") }})</span>
              <div class="refresh-btn" @click="refresh()"><van-icon name="replay" /></div>
            </div>
          </div>
          <div :style="{ marginTop: menu_top + 'px' }" class="menu">
            <div class="menu-item" @click="$router.push({ path: '/Personalreport' });">
              <van-image class="menu-item-icon" src="img/mine/baobiao.png">
                <template v-slot:loading>
                  <van-loading type="spinner" />
                </template>
              </van-image>
              <span class="menu-item-label">{{ $t("my.my_statement") }}</span>
            </div>
            <!-- <div class="menu-item" @click="exit()">
                <van-image class="menu-item-icon" src="img/mine/mingxi.png">
                  <template v-slot:loading>
                    <van-loading type="spinner"/>
                  </template>
                </van-image>
                <span class="menu-item-label" >{{$t("my.account_detail")}}</span>
              </div> -->
            <div class="menu-item" @click="$router.push({ path: '/GameRecord' });">
              <van-image class="menu-item-icon" src="img/mine/youxi.png">
                <template v-slot:loading>
                  <van-loading type="spinner" />
                </template>
              </van-image>
              <span class="menu-item-label">{{ $t("my.task_record") }}</span>
            </div>
            <div class="menu-item" @click="$router.push({ path: '/RechargeRecords' });">
              <van-image class="menu-item-icon" src="img/mine/youxi.png">
                <template v-slot:loading>
                  <van-loading type="spinner" />
                </template>
              </van-image>
              <span class="menu-item-label">{{ $t("recharge.rechargeRecords") }}</span>
            </div>
            <div class="menu-item" @click="$router.push({ path: '/WithdrawRecord' });">
              <van-image class="menu-item-icon" src="img/mine/youxi.png">
                <template v-slot:loading>
                  <van-loading type="spinner" />
                </template>
              </van-image>
              <span class="menu-item-label">{{ $t("title.withdrawRecord") }}</span>
            </div>                        
            <div class="menu-item" @click="$router.push({ path: '/Infomation' });">
              <van-image class="menu-item-icon" src="img/mine/user.png">
                <template v-slot:loading>
                  <van-loading type="spinner" />
                </template>
              </van-image>
              <span class="menu-item-label">{{ $t("my.personal_center") }}</span>
            </div>
            <div class="menu-item" @click="toNotice()">
              <van-image class="menu-item-icon" src="img/mine/gonggao.png">
                <template v-slot:loading>
                  <van-loading type="spinner" />
                </template>
              </van-image>
              <span class="menu-item-label">{{ $t("my.information_announcement") }}</span>
            </div>
            <!-- <div class="menu-item" @click="toService()">
                <van-image class="menu-item-icon" src="img/mine/kefu_1.png">
                  <template v-slot:loading>
                    <van-loading type="spinner"/>
                  </template>
                </van-image>
                <span class="menu-item-label" >{{$t("my.online_service")}}</span>
              </div> -->
          </div>
        </div>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userInfo: {},
      menu_top: 40,
      isLoading: false,
      baseInfo: {}
    };
  },
  methods: {
    refresh() {
      this.isLoading = true;

      this.getUserInfo();
      // setTimeout(() => {
      //   this.isLoading = false;
      //   if(localStorage.getItem('token')){
      //     this.$toast(this.$t("reservation.refresh"));
      //   }else{
      //     this.$router.push({path:'/Login'})
      //   }
      // }, 500);
    },
    exit() {
      this.$toast(this.$t("my.finish_task"));
    },
    getBaseInfo() {
      this.$http({
        method: 'get',
        url: 'base_info'
      }).then(res => {
        this.isLoading = false
        if (!localStorage.getItem('token')) {
          this.$router.push({ path: '/Login' })
        }
        this.baseInfo = res.data
        console.log(res.data);
      })
    },
    showSetting() {
      if (localStorage.getItem('token')) {
        this.$router.push({ path: '/Setting' })
      } else {
        this.$router.push({ path: '/Login' })
      }
    },
    toNotice() {
      if (localStorage.getItem('token')) {
        this.$router.push({ path: '/Notice' })
      } else {
        this.$router.push({ path: '/Login' })
      }
    },
    onRefresh() {
      setTimeout(() => {
        this.isLoading = false;
        if (localStorage.getItem('token')) {
          this.getUserInfo();
          this.$toast(this.$t("reservation.refresh"));
        } else {
          this.$router.push({ path: '/Login' })
        }
      }, 500);
    },
    doLogin() {
      if (localStorage.getItem('token')) {
        this.$router.push({ path: '/Infomation' });
      } else {
        this.$router.push({ path: '/Login' })
      }
    },
    doPay() {
      if (!this.baseInfo.isopen) {
        this.isLoading = true
        return
      }
      if (this.baseInfo.isopen == 0) {
        this.$router.push({
          name: 'RechargeType',
          params: {
            'balance': this.userInfo.money
          }
        })
      } else {
        this.$router.push("ServiceOnline")
      }
    },
    doWithdrawal() {
      this.$http({
        method: 'get',
        url: 'user_get_bank'
      }).then(res => {
        if (res.data.is_bank) {
          this.$router.push({
          name: 'WithdrawalType'
        })
        } else {
          this.$router.push("Setbank");
          this.$toast.fail(this.$t("setting.set_bank"));
        }
      })
    },
    toService() {
      if (this.$store.getters.getBaseInfo.iskefu == 1) {
        this.$router.push("ServiceOnline");
      } else {
        this.$toast.fail(this.$t("setting.forbid"));
      }
    },
    getUserInfo() {
      this.$http({
        method: 'get',
        url: 'user_info'
      }).then(res => {
        if (res.code === 200) {
          this.isLoading = false;
          this.userInfo = res.data;
          this.menu_top = 15;
          if (this.userInfo.status !== 1) {
            this.$toast(this.$t("video.account_out"));
            localStorage.clear()
            this.$router.push({ path: '/Login' })
          }
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      })
    },
  },
  created() {
    if (localStorage.getItem('token')) {
      this.getUserInfo();
      this.getBaseInfo()
    } else {
      this.userInfo.username = this.$t("setting.log_reg");
      this.userInfo.ip = this.$t("setting.more_service");
      this.userInfo.header_img = "img/mine/avatar.png";
    }
  }
};
</script>

<style scoped>
.header-bottom {
  display: flex;
}

.header-bottom-item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  padding: 15px;
  border-top: 1px solid #e4e4e4;
  color: #fff;
}

.header-bottom-item:nth-child(1) {
  border-right: 1px solid #e4e4e4;
}

.header-bottom-icon {
  width: 50px;
  height: 50px;
  margin-right: 20px;
}

.page {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  background-color: #f2f2f5;
}

.mine {
  position: relative;
  bottom: 10px;
  background: #f2f2f5;
}

.mine .wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.nav-bar {
  background: transparent;
}

.mine .header {
  background: linear-gradient(90deg,#00bfff,#87ceeb,#87cefa);
  /* padding-bottom: 100px; */
}

::v-deep .van-nav-bar__content {
  height: 100px;
}

::v-deep .van-hairline--bottom::after {
  border-bottom-width: 0px;
}

.mine .header .van-nav-bar .van-icon {
  font-size: 45px;
}

.mine .header .user-wrapper {
  display: flex;
  align-items: center;
  margin: 0px 40px 0px 40px;

}

.mine .user_img {
  height: 130px;
  width: 130px;
}

::v-deep .van-loading__spinner {
  height: 50px;
  width: 50px;
}

::v-deep .van-image__error-icon {
  font-size: 70px;
}

.mine .header .user-wrapper .login-content {
  flex: 1;
  margin-left: 30px;
}

.mine .header .user-wrapper .login-content .login-btn {
  display: inline-block;
  font-size: 40px;
  line-height: 0px;
  color: #fff;
}

.mine .header .user-wrapper .login-content .login-label {
  margin-top: -13px;
  font-size: 28px;
  color: hsla(0, 0%, 100%, .6);
}

.mine .page-bg {
  height: 500px;
  background: linear-gradient(90deg,#00bfff,#87ceeb,#87cefa);
}

.mine .content {
  position: relative;
  /* padding: 10px 30px 30px; */
  min-height: 500px;
  background-color: #f2f2f5;
}

::v-deep .van-pull-refresh__track .van-pull-refresh__head * {
  color: #ffffff;
  font-size: 35px;
}

.mine .wrapper .content .finance {
  position: absolute;
  display: flex;
  align-items: center;
  top: -55px;
  left: 30px;
  right: 30px;
  height: 120px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
}

.mine .wrapper .content .finance .line {
  width: 3px;
  height: 40px;
  background-color: #ccc;
}

.mine .wrapper .content .finance .finance-item {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.mine .wrapper .content .finance .finance-item .text {
  margin-left: 30px;
  font-size: 30px;
  color: #000;
  font-weight: 500;
}

.mine .wrapper .content .finance .finance-item .icon {
  font-size: 50px;
}

.mine .wrapper .content .menu {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  background-color: #fff;
  justify-content: space-between;
  height: 500px;
  /* border-radius: 15px; */
  /* box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25); */
}

.mine .wrapper .content .menu .menu-item {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  width: 200px;
  height: 200px;
}

.mine .wrapper .content .menu .menu-item .menu-item-label {
  font-size: 25px;
  /* color: rgb(181, 181, 181); */
  font-weight: 500;
}

.menu-item:nth-child(1) {
  color: rgb(255, 112, 69) !important;
}

.menu-item:nth-child(2) {
  color: blue !important;
}

.menu-item:nth-child(3) {
  color: rgb(255, 112, 69) !important;
}

.menu-item:nth-child(4) {
  color: blue !important;
}

.mine .wrapper .content .menu .menu-item .menu-item-icon {
  margin: 25px;
  width: 100px;
  height: 100px;
  -o-object-fit: contain;
  object-fit: contain;
}

.mine .wrapper .content .wallet {
  /* margin-top: 80px; */
  padding: 20px 30px;
  background-color: #fff;
  /* border-radius: 15px; */
  /* box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25); */
}

.mine .wrapper .content .wallet .part-1 {
  display: flex;
  align-items: center;
  height: 80px;
}

.mine .wrapper .content .wallet .font-primary-color {
  color: #000;
}

.font-gray {
  color: #868686;
}

.mine .wrapper .content .wallet .part-2 {
  display: flex;
  align-items: center;
  height: 80px;
}

.mine .wrapper .content .wallet .part-2 .balance {
  flex: 1;
  font-size: 45px;
  color: rgb(255, 112, 69) !important;
  font-weight: 700;
}

.mine .wrapper .content .wallet .van-hairline--bottom::after {
  border-bottom-width: 3px;
}

.mine .wrapper .content .wallet .part-2 .refresh-btn {
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  font-size: 35px;
  border-radius: 50%;
  color: rgb(255, 112, 69);
  /* background-color: #e6c3a1; */
}
</style>
