<template>
  <div class="container page">
    <van-nav-bar :title="$t('title.RechargeType')" class="nav-bar">
      <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()" />
      </template>
    </van-nav-bar>
    <div class="sex">
      <van-radio-group v-model="rechargeMethod">
        <div class="item van-hairline--bottom" @click="chooesMethod('bank')">
          <van-radio name="bank">{{ $t("my.bank_addr") }}</van-radio>
        </div>
        <div class="item van-hairline--bottom" @click="chooesMethod('usdt')">
          <van-radio name="usdt">Wallet</van-radio>
        </div>
      </van-radio-group>

    </div>
    <div class="button" @click="submit">{{ $t('my.submit') }}</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      rechargeMethod: "",
    };
  },
  methods: {
    back() {
      return window.history.back();
    },
    chooesMethod(method) {
      this.rechargeMethod = method;
    },
    submit() {
      if (this.rechargeMethod == "bank") {
        this.$router.push("ServiceOnline");
        return;
      }
      this.$router.push({ path: 'RechargeInfo?method=' + this.rechargeMethod })
    }
  },
  created() {
    if (!localStorage.getItem('token')) {
      this.$router.push({ path: '/Login' })
    }
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";

.button {
  margin: 30px auto;
  padding: 20px;
  text-align: center;
  color: #fff;
  background: #00eeff;
  border-radius: 100px;
}

.container .sex {
  background-color: #fff;
  padding: 0 40px;
}

.container .sex .item {
  font-size: 35px;
  line-height: 50px;
  padding: 30px 0;
}

::v-deep .van-radio__label {
  line-height: 50px;
  margin-left: 30px;
}

::v-deep .van-radio__icon {
  font-size: 30px;
}

::v-deep .van-radio__icon--checked .van-icon {
  color: #fff;
  border-color: rgb(126, 86, 120);
  background-color: rgb(126, 86, 120);
}

.container .van-hairline--bottom::after {
  border-bottom-width: 3px;
}
</style>
